/* eslint-disable react/prop-types */
import 'styles/scss/main.scss';
// import { AnimatePresence } from 'framer-motion';
import { initSentry } from 'services/sentry';

initSentry();

function MyApp({ Component, pageProps, router, err }) {
    return (
        // <AnimatePresence exitBeforeEnter>
        <Component {...pageProps} route={router.route} err={err} />
        // </AnimatePresence>
    );
}

export default MyApp;
